import { useState, useEffect } from 'react';
import { Tab, Box, Fade, Stack } from '@mui/material';
import { ShPaper } from 'shared/SharedStyles/ShSurfaces';
import { SettingsAccessibility } from '@mui/icons-material';
import { ShTabs } from 'shared/SharedStyles/ShNavigation';
import { ApplicantQuestions } from './ApplicantQuestions';
import ApplicantAIInterviewQuestions from './ApplicantAiInterviewQuestions';
import GradingOutlinedIcon from '@mui/icons-material/GradingOutlined';
import { useAppSelector } from 'helpers/hooks';

export const ApplicantInterviewKitTabs = () => {
  const { applicantDetails } = useAppSelector((state) => state.employer.applicants.applicantProfile);
  const hasPersonalityTab = applicantDetails && !applicantDetails?.atsPurchased;

  // Ensure that the active tab remains valid when tabs change
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    if (!hasPersonalityTab && activeTab === 0) {
      setActiveTab(0); // Reset to Resume Focused Questions if Personality tab is removed
    }
  }, [hasPersonalityTab, activeTab]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <ShPaper variant="outlined" borderRadius={15}>
      <Stack spacing={2}>
        <ShTabs highlightVariant="underline" value={activeTab} onChange={handleTabChange} variant="scrollable">
          {hasPersonalityTab && (
            <Tab icon={<SettingsAccessibility color="primary" />} iconPosition="start" label="Personality Focused Questions" />
          )}
          <Tab icon={<GradingOutlinedIcon color="primary" />} iconPosition="start" label="Resume Focused Questions" />
        </ShTabs>

        <Box>
          {hasPersonalityTab ? (
            <>
              <Fade in={activeTab === 0} timeout={1000}>
                <Box>{activeTab === 0 && <ApplicantQuestions />}</Box>
              </Fade>
              <Fade in={activeTab === 1} timeout={1000}>
                <Box>{activeTab === 1 && <ApplicantAIInterviewQuestions />}</Box>
              </Fade>
            </>
          ) : (
            <Fade in={activeTab === 0} timeout={1000}>
              <Box>{activeTab === 0 && <ApplicantAIInterviewQuestions />}</Box>
            </Fade>
          )}
        </Box>
      </Stack>
    </ShPaper>
  );
};
